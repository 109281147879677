<template>
  <div>
    <Consult></Consult>
  </div>

</template>
<script>
import Consult from '../../components/Consult.vue'

export default {
  data () {
    return {
    }
  },
  components: {
    Consult
  }
}
</script>
